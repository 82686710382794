import { GetServerSideProps } from 'next';
import { ThemeProvider } from 'styled-components';

import AppInstallActionSheet from '@/components/appInstallActionSheet/AppInstallActionSheet';
import Header from '@/components/header/Header';
import OnboardingHomepage from '@/components/homepage/OnboardingHomepage';
import HeaderRefresh from '@/components/homepageRefresh/header/Header';
import HomepageLayout from '@/components/homepageRefresh/homepage/HomepageLayout';
import { homepageTheme } from '@/components/homepageRefresh/homepage/HomepageLayout/data/theme';
import Layout from '@/components/layout/LayoutWrapper';
import { InitRequest } from '@/hooks/store';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { BlogPost, getBlogPosts } from '@/utils/blogPosts';
import { getInitialServerState } from '@/utils/pageGeneration/getServerSideProps';

const HomepageLayouts: React.FC<{ isNewHomepageEnabled?: boolean; blogPosts: BlogPost[] }> = ({
	isNewHomepageEnabled,
	blogPosts,
}) => {
	return isNewHomepageEnabled ? (
		<>
			<ThemeProvider theme={homepageTheme}>
				<HeaderRefresh />
				<HomepageLayout blogPosts={blogPosts} />
			</ThemeProvider>
		</>
	) : (
		<>
			<Header />
			<OnboardingHomepage />
		</>
	);
};

const Home = ({
	isNewHomepageEnabled,
	blogPosts,
}: {
	isNewHomepageEnabled: boolean;
	blogPosts: BlogPost[];
}) => {
	const { isEnrolled } = useAmplitudeExperiment('www-app-install-action-sheet');

	return (
		<Layout pageName="homepage" showFooter>
			{isEnrolled && <AppInstallActionSheet />}
			<HomepageLayouts blogPosts={blogPosts} isNewHomepageEnabled={isNewHomepageEnabled} />
		</Layout>
	);
};

export const getServerSideProps: GetServerSideProps = async context => {
	const initialState = await getInitialServerState(context, [
		InitRequest.DEVICE,
		InitRequest.USER,
		InitRequest.PRICES,
	]);

	const isNewHomepageEnabled = initialState?.device?.language?.toLocaleLowerCase() === 'en';
	const blogPosts = isNewHomepageEnabled ? await getBlogPosts('all') : [];

	return {
		props: {
			initialState,
			isNewHomepageEnabled,
			blogPosts,
		},
	};
};

export default Home;
